<template>
  <div class="preview-wrapper">
    <slot />
    <NuxtErrorBoundary @error="() => {}">
      <Swiper
        class="slider"
        :modules="[SwiperScrollbar, SwiperMousewheel]"
        slides-per-view="auto"
        :mousewheel="{
          enabled: true,
          forceToAxis: true,
          releaseOnEdges: true,
        }"
        :space-between="16"
        :scrollbar="{
          enabled: true,
        }"
      >
        <SwiperSlide
          v-for="preview in previews"
          :key="preview"
          class="slider__item"
          :lazy="true"
        >
          <BlogPreview :preview="preview" />
        </SwiperSlide>
      </Swiper>
    </NuxtErrorBoundary>
  </div>
</template>

<script setup>
import BlogPreview from "~/modules/blog/components/BlogPreview.vue";
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

const { data: previews } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type: useRuntimeConfig().public.contentful.contentType.blog,
    order: "-fields.created",
    limit: 4,
  },
});
</script>

<style scoped lang="scss">
.preview-wrapper {
  @extend %width-main;

  @include flex-container(column, flex-start);
  gap: 16px;
}

.slider {
  width: 100%;

  min-width: 0;
  min-height: 0;

  &.swiper {
    --swiper-scrollbar-bottom: 1px;
    --swiper-scrollbar-bg-color: var(--color-sky-base);
    --swiper-scrollbar-drag-bg-color: var(--color-primary-base);
    --swiper-scrollbar-size: 2px;
  }

  &__item {
    width: 348px;
    height: auto;

    @include mobile {
      width: 164px;
    }
  }
}
</style>
